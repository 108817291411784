import { h } from "preact";
import { Router } from "preact-router";
import { Link } from "preact-router/match";

import Logo from "./Logo";
import Nav from "./nav";
import Player from "./player";

import SimplePage from "../routes/simplePage";
import Posts from "../routes/posts";
import Radio from "../routes/radio";
import { useState, useEffect } from "preact/hooks";

const App = () => {
	const [pages, setPages] = useState([]);
	const [posts, setPosts] = useState([]);
	const [playerStatus, setPlayerStatus] = useState({
		live: true,
		url: "",
		title: ""
	});
	const [AisPlaying, setAIsPlaying] = useState(false);
	const [VisPlaying, setVIsPlaying] = useState(false);
	const [liveUrl, setLiveUrl] = useState("");

	const [subNav, setSubNav] = useState([])

	useEffect(() => {
		const pageUrl = "https://admin.la-sira.fr/wp-json/wp/v2/main_page";
		const postUrl = "https://admin.la-sira.fr/wp-json/wp/v2/posts";
		const liveUrl = "https://admin.la-sira.fr/wp-json/wp/v2/live";
		fetch(liveUrl)
			.then(res => res.json())
			.then(res => setLiveUrl(res[0].title.rendered));
		fetch(pageUrl)
			.then(res => res.json())
			.then(res => {
				setPages(res);
			});
		fetch(postUrl)
			.then(res => res.json())
			.then(res => {
				setPosts(res);
			});
	}, []);

	useEffect(() => {
		if (liveUrl) setPlayerStatus({ ...playerStatus, url: liveUrl });
	}, [liveUrl]);

	const handleIsPlaying = (audio, video) => {
		setAIsPlaying(audio);
		setVIsPlaying(video);
	};

	const handlePlayer = (bool, url, title) => {
		setPlayerStatus({
			...playerStatus,
			live: bool,
			url,
			title
		});
	};


	return (
		<div id="app" className="app">
			<div id="logo">
				<Link href="/">
				<Logo />
				</Link>
			</div>
			<Nav links={subNav}/>
			<Player
				content={playerStatus}
				handlePlaying={handleIsPlaying}
				isPlaying={AisPlaying}
				url={liveUrl}
			/>
			<Router>
				<SimplePage
					path="/"
					content={pages.length ? pages[pages.length - 2] : {}}
				/>
				<SimplePage
					path="/Studio-Photo/"
					content={pages.length ? pages[pages.length - 1] : {}}
				/>
				<Posts
					path="/Galerie/:id*"
					content={posts.length ? posts : null}
					postId={posts.length ? posts[0].id : null}
				/>
				<Radio
					path="/Radio/:rest*"
					handlePlayer={handlePlayer}
					handlePlaying={handleIsPlaying}
					VisPlaying={VisPlaying}
					AisPlaying={AisPlaying}
					playerStatus={playerStatus}
				/>
			</Router>
		</div>
	);
};

export default App;
