import { h } from "preact";
import { useState, useEffect } from "preact/hooks";
import { Link } from "preact-router/match";
import style from "./style.css";
import SubNav from "../subnav"

const Nav = ({links}) => {
	return (
		<nav class={style.nav}>
			<div class={style.navContent}>
				<Link activeClassName={style.active} href="/">
					La Sira
				</Link>
				<Link activeClassName={style.active} href="/Studio-Photo">
					Studios
				</Link>
				<Link activeClassName={style.active} href="/Galerie/:id*">
					Galerie
				</Link>
				<Link activeClassName={style.active} href="/Radio/:rest*">
					Radio
				</Link>
			</div>
			{/* <div class={style.subnavContent}>
				{links.map( el => {
					return(
						<Link activeClassName={style.active} href={el.link}>
							{el.title}
						</Link>
					)
				})}
			</div> */}
			<SubNav />
		</nav>
	);
};

export default Nav;