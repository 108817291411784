import { h } from "preact";
import { Router } from "preact-router";
import { Link } from "preact-router/match";
import { useState, useEffect } from "preact/hooks";
import style from "./style.css";

const SubMen = ({links}) => {
    return(
        <>
        {links.map( el => {
            return(
                <Link activeClassName={style.active} href={
                    el.type == "radio"
                        ? `/Radio/${el.id}`
                        : `/Galerie/${el.id}`
                }>
                {el.title.rendered}
                </Link>
                )
            })
        }
        </>
    )
};

const SubNav = ({...props}) => {

    const [radios, setRadios] = useState([]);
	const [posts, setPosts] = useState([]);
	
    const updateGaleries = () => {
        let postUrl = "https://admin.la-sira.fr/wp-json/wp/v2/posts?_fields=id,title,type,link";
        fetch(postUrl)
            .then(res => res.json())
            .then(res => {
                setPosts(res);
            });
    }

    const updateRadios = () => {
        let postUrl = "https://admin.la-sira.fr/wp-json/wp/v2/radio?_fields=id,title,type,link";
        fetch(postUrl)
            .then(res => res.json())
            .then(res => {
                setRadios(res);
            });
    }

    const handleRoute = async e => {
        if(e.url.startsWith("/Galerie/")) {
            updateGaleries();
        } else if(e.url.startsWith("/Radio/")) {
            updateRadios();
        }
    }

    useEffect(() => {
        updateGaleries();
        updateRadios();
	}, []);

	return (
        <div class={style.subnavContent}>
            <Router>
                <SubMen path={`/Galerie/:id*`} links={posts}/>
                <SubMen path={`/Radio/:id*`} links={radios}/>
            </Router>
        </div>
	);
};

export default SubNav;