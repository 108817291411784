import { h } from "preact";
import style from "./style.css";

import Logo from "./../Logo";
import { useState, useEffect, useRef } from "preact/hooks";

const PlayerTimeline = ({ duration, status, type, handleTime }) => {
	const timeline = useRef(null);

	useEffect(() => {
		if (timeline.current) {
			timeline.current.addEventListener("click", e => {
				let offsets = timeline.current.getBoundingClientRect();
				const mousePosition = e.clientX - offsets.left;
				const position = mousePosition / timeline.current.offsetWidth;

				handleTime(position);
			});
		}
	}, [timeline.current]);

	return (
		<>
			<div class={style.whitebg} />

			{status || !type ? (
				<>
					<div class={style.blackbg} style={{ width: duration }} />

					<div class={style.makered} ref={timeline} />
				</>
			) : null}
		</>
	);
};

const Player = ({ content, handlePlaying, isPlaying, url }) => {
	const player = useRef(null);
	const duration = useRef(null);
	const playButton = useRef(null);
	const clickArea = useRef(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [mediaTime, setMediaTime] = useState(0);

	/* Handle the arrival of the new data => 
	- the url wanted or if not the basic live one
	- Fire autoplay if the playable content is not live
	- Handle some styling changes between live and other
	*/
	useEffect(() => {
		setError(false);
		handlePlaying(false, false);
		player.current.src = content.url ? `${content.url}?_=` + Date.now() : `${url}?_=` + Date.now();

		// handle auto play for regular data
		if (!content.live) {
			handleClickeableArea();
			player.current.addEventListener(
				"loadeddata",
				() => {
					if (player.current.readyState >= 2) {
						handleStatus(true);
					}
				},
				{ once: true }
			);
		}
		// Handle display of time if it's not live
		if (duration.current)
			duration.current.innerText = content.live ? "Live" : "00:00";
	}, [content]);

	/* Handle isPlaying global variable and how the player should react */
	useEffect(() => {
		if (isPlaying) player.current.play();
		else player.current.pause();
	}, [isPlaying]);

	/* Handle all the play status of the player 
	- If we wants autoplay or not
	- If it's need to pause or play
	- Loading of the file we want to hear (mostly for safari )
	- If playing and not live set the timeline width 
	*/
	const handleStatus = forcePlay => {
		//Pause
		if (isPlaying && forcePlay !== true) {
			setLoading(false);
			player.current.pause();
			handlePlaying(false, false);
		} else {
			//Play
			if (!content.live) {
				player.current.addEventListener("timeupdate", function() {
					//Set Timeline Width
					const mediaTmp = (this.currentTime / this.duration) * 100;
					if (duration.current)
						duration.current.innerText = secondsToHms(this.currentTime);
					setMediaTime(mediaTmp);
				});
			}
			//Launching play handling errors and loading
			if (player.current.currentTime === 0) setLoading(true);
			let promise = player.current.play();

			if (promise !== undefined) {
				promise
					.catch(error => {
						setError(true);
						handlePlaying(false, false);
					})
					.then(() => {});
			}
			player.current.addEventListener(
				"canplaythrough",
				function() {
					setLoading(false);
				},
				{ once: true }
			);
			handlePlaying(true, false);
		}
	};

	// Display time in hours, minutes, seconds
	function secondsToHms(d) {
		d = Number(d);
		let h = Math.floor(d / 3600);
		let m = Math.floor((d % 3600) / 60);
		let s = Math.floor((d % 3600) % 60);

		let hDisplay = h > 0 ? (h < 10 ? "0" + h + ":" : h + ":") : "";
		let mDisplay = m > 0 ? (m < 10 ? "0" + m + ":" : m + ":") : "00:";
		let sDisplay = s > 0 ? (s < 10 ? "0" + s : s) : "00";
		return hDisplay + mDisplay + sDisplay;
	}

	// Set new player current time if we clicked on the timeline
	const handleTime = position => {
		player.current.currentTime = player.current.duration * position;
	};

	const handleClickeableArea = () => {
		clickArea.current.style.width = `calc(${playButton.current.offsetWidth}px + 2.5rem)`;
		clickArea.current.style.left = `calc(${playButton.current.offsetLeft}px - .5rem)`;
	};

	return (
		<div className={style.Player} onClick={content.live ? handleStatus : null}>
			<PlayerTimeline
				duration={content.live ? "100%" : `${mediaTime}%`}
				status={isPlaying}
				type={content.live}
				handleTime={handleTime}
			/>

			{!content.live ? (
				<h1 class={style.notInMobile}>{content.title}</h1>
			) : null}

			<span
				ref={playButton}
				class={
					isPlaying || !content.live
						? style.playButton
						: style.playButtonDisable
				}
			>
				{error
					? "No stream"
					: isPlaying
					? loading
						? "Loading..."
						: "Pause"
					: loading && !content.live
					? "Loading..."
					: "Play"}
			</span>
			{!content.live ? <h1 ref={duration} /> : <h2>Live</h2>}
			<div class={style.clickArea} onClick={handleStatus} ref={clickArea} />
			<audio name="media" ref={player} playsinline autoplay={!content.live}>
				<source src={content.url} type="audio/mpeg" />
			</audio>
		</div>
	);
};

export default Player;
